import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TABLET } from '../../constants/breakpoints'

const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  padding: 2rem;
  padding-top: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: ${TABLET}px) {
    display: block;
    width: 100%;
    padding: 0;
  }
`

const Heading = styled.h2`
  margin-top: 0;
  padding-bottom: 0.5rem;
  font-size: 1.25rem !important;
  border-bottom: solid 10px ${(props) => props.color};
  margin-bottom: 1rem;
`

const SubHeading = styled.p`
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
`

const Description = styled.div``

const Step = ({ width, number, subTitle, title, color, children }) => (
  <Container width={width} className="step">
    {number && <SubHeading>Step {number}</SubHeading>}
    {subTitle && <SubHeading>{subTitle}</SubHeading>}
    <Heading color={color}>{title}</Heading>
    <Description>{children}</Description>
  </Container>
)

Step.defaultProps = {
  number: '',
  subTitle: '',
  width: '100%',
}

Step.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  number: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
}

export default Step
