import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Background from '../../components/Background'
import Step from '../../components/Home/step'
import { TABLET } from '../../constants/breakpoints'
import { LIGHTEST, PRIMARY, SECONDARY, TERTIARY } from '../../constants/colors'
import { METADATA } from '../../config/howToClaim'
import { Link } from 'gatsby'
import Anchor from '../../components/Anchor'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 auto;

  @media screen and (max-width: ${TABLET}px) {
    display: block;
    padding-bottom: 3rem;
  }
`

const Heading = styled.h1`
  font-size: 1.5rem !important;
  line-height: 180% !important;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.25rem !important;
  }
`

const SubHeading = styled.h2`
  font-size: 1.5rem !important;
  line-height: 180% !important;
  text-transform: uppercase;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.25rem !important;
  }
`

const Body = styled.section`
  width: 100%;
  padding-top: 5rem;
  padding-right: 5rem;
  padding-left: 10rem;

  p {
    margin-bottom: 0.75rem;
  }

  ul,
  ol {
    margin: 0;
    margin-bottom: 1rem;
  }

  li {
    padding-left: 0.5rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: ${TABLET}px) {
    width: 100%;
    padding: 0;
  }
`

const BackgroundImage = styled(Background)``

const Steps = styled.div`
  display: flex;
  padding: 0 2rem;
  margin: 0 auto;

  strong {
    font-weight: bold;
  }

  sup,
  small {
    font-size: 0.8rem;
    margin: 0;
  }

  @media screen and (max-width: ${TABLET}px) {
    display: block;
  }
`

const List = styled.ul`
  margin-left: 0;
`

const Divider = styled.hr`
  border: solid 1px ${PRIMARY};
  margin-bottom: 3rem;
`

const PlainText = styled.div`
  max-width: ${TABLET}px;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 3rem;
`

const Page = () => (
  <Layout {...METADATA}>
    <BackgroundImage
      color={LIGHTEST}
      filename="pattern.png"
      size="180%"
      repeat="repeat"
      position="2.5rem 7.5rem"
    >
      <Container>
        <Body>
          <Heading>How to claim</Heading>
          <Steps width="33%">
            <Step title="When you need to let us know" color={TERTIARY}>
              <p>
                We must be notified within 90 days of any event that will lead
                to a claim.
              </p>
              <p>
                Please ensure that your covered employees are aware of this and
                know how to reach us to make their claim.
              </p>
            </Step>

            <Step title="What you need to give us" color={SECONDARY}>
              <p>
                What you need to provide for each type of claim is set out
                below. This information is also available in your policy
                document, and our claims team can give more detail where needed.
              </p>
            </Step>

            <Step title="Contact us" color={PRIMARY}>
              <p>
                Make sure your loved ones know that you have taken out this
                policy, and how to contact us if they ever need to claim. We are
                in this business to pay benefits to those in need, and we are
                standing by to help:
              </p>
              <ul>
                <li>
                  Call us on <a href="tel:+27210451469">021 045 1469</a>
                </li>
                <li>
                  Email us at{' '}
                  <a href="mailto:claims@randmutual.co.za">
                    claims@randmutual.co.za
                  </a>
                </li>
                <li>
                  Let us <Link to="/call-me">call you</Link>
                </li>
              </ul>
            </Step>
          </Steps>

          <Steps width="25%">
            <Step subTitle="Claim" title="Terminal illness" color={TERTIARY}>
              <List>
                <li>Certified copy of insured person’s ID</li>
                <li>Terminal Illness claim form</li>
                <li>Terminal Illness medical report</li>
              </List>
            </Step>

            <Step subTitle="Claim" title="Life cover" color={SECONDARY}>
              <List>
                <li>Certified copy of death certificate</li>
                <li>Certified copy of insured person’s ID</li>
                <li>Certified copy of the beneficiary’s ID</li>
                <li>Completed death claim form</li>
                <li>Completed medical report</li>
              </List>
            </Step>

            <Step subTitle="Claim" title="Funeral cover" color={PRIMARY}>
              <List>
                <li>Certified copy of death certificate</li>
                <li>Certified copy of insured person’s ID</li>
                <li>Certified copy of the beneficiary’s ID</li>
                <li>Completed funeral claim form</li>
                <li>DHA1663 – notiﬁcation of death register</li>
                <li>Claimant/Beneﬁciary 1 month bank statement</li>
              </List>
            </Step>

            <Step subTitle="Claim" title="Disability cover" color={TERTIARY}>
              <List>
                <li>Certified copy of insured person’s ID</li>
                <li>Completed claim form</li>
                <li>
                  Completed medical report form together with copies of any
                  specialist reports and investigations relating to the claim
                  cause
                </li>
              </List>
            </Step>
          </Steps>

          <Steps width="25%">
            <Step subTitle="Forms" title="Certificates" color={SECONDARY}>
              <ul>
                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Certificate%20of%20Medical%20Attendant%20-%20Death%20Claim.pdf">
                    Certificate of Medical Attendant - Death Claim
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Certificate%20of%20Medical%20Attendant%20-%20Terminal%20Illness%20Claim.pdf">
                    Certificate of Medical Attendant - Terminal Illness Claim
                  </Anchor>
                </li>
              </ul>
            </Step>

            <Step subTitle="Forms" title="Claims" color={PRIMARY}>
              <ul>
                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Claim%20form%20-%20Life%20.pdf">
                    Claim Form - Life
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Claim%20form%20-%20Capital%20Disability.pdf">
                    Claim Form - Capital Disability
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Claim%20form%20-%20Terminal%20Illness.pdf">
                    Claim Form - Terminal Illness
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Claim%20form%20-%20Funeral.pdf">
                    Claim Form - Funeral
                  </Anchor>
                </li>
              </ul>
            </Step>

            <Step subTitle="Forms" title="Questionnaires" color={TERTIARY}>
              <ul>
                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Occupation%20Questionnaire%20-%20Employer.pdf">
                    Occupation Questionnaire - Employer
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Occupation%20Questionnaire%20-%20Insured%20or%20Member.pdf">
                    Occupation Questionnaire - Insured or Member
                  </Anchor>
                </li>
              </ul>
            </Step>

            <Step subTitle="Forms" title="Other" color={SECONDARY}>
              <ul>
                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Family%20Funeral%20and%20Beneficiary%20Details.pdf">
                    Family Funeral and Beneficiary Details
                  </Anchor>
                </li>

                <li>
                  <Anchor href="https://storage.googleapis.com/rml-public/Police%20Report.pdf">
                    Police Report
                  </Anchor>
                </li>
              </ul>
            </Step>
          </Steps>

          <Divider />

          <PlainText>
            <SubHeading>Additional information</SubHeading>
            <p>
              We also have the right to ask for additional supporting
              information from time to time. For example, if the insured person
              dies within the first 2 years, we might need the following
              additional information:
            </p>
            <List>
              <li>Police report/statement completed by the police</li>
              <li>Copy of the post-mortem report</li>
              <li>Result of any forensic laboratory investigations</li>
              <li>Inquest findings (if appropriate)</li>
              <li>Full verdict in the case of murder (if appropriate)</li>
            </List>
            <p>
              The underwriter of this policy is RMA Life, a registered long-term
              insurer, and they are ultimately responsible for paying any claims
              on RMA Life policies. RMA Life depends on you and your
              beneficiaries to be completely truthful both in your original
              application and during the claims process.
            </p>
            <p>
              If you are not truthful or do not disclose all relevant
              information, or misrepresent information to us, RMA Life has the
              right to cancel your policy, or change the terms of your policy.
            </p>
            <p>
              If your claim is based on false or incomplete information, RMA
              Life will not pay the claim. If, after RMA Life pays any claim,
              RMA Life finds that it was based on false or incomplete
              information, all claim payments will have to be refunded to them.
            </p>
          </PlainText>
        </Body>
      </Container>
    </BackgroundImage>
  </Layout>
)

export default Page
