export const METADATA = {
  route: '/how-to-claim',
  imagePath: 'social-how-to-claim.jpg',
  pageType: 'website',
  title: 'How to claim',
  description: `
    Find out everything you need for each type of
    RMA Group Life Cover claim.
  `,
}
